.card-responsive {
    width: 500px;
    min-width: 500px;
    max-width: 800px;
  }
  
  @media screen and (max-width: 768px) {
    .card-responsive {
      width: 350px;
      min-width: 350px;
    }
  }

  