.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .overlay-content {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 400px;
    text-align: left;
  }
  
  .overlay-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .overlay-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .back-button {
    background: #b8b9ba; /* blue */
    color: #fff;
  }
  
  .uncheck-button {
    background: #dc3545; /* red */
    color: #fff;
  }
  
  .checkin-button {
    background: #28a745; /* green */
    color: #fff;
  }