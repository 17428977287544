.container {
    max-width: 960px;
   }
   
   .card {
    border-radius: 0.25rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
   }
   
   .card-title {
    margin-bottom: 1rem;
   }
   
   .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
   }
   
   .btn-primary:hover {
    background-color: #0056b3;
    border-color: #004499;
   }