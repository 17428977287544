.container {
    max-width: 960px;
   }
   
   .card {
    border-radius: 0.25rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
   }
   
   .card-img-top {
    width: 100%;
    height: auto;
   }
   
   .card-title {
    margin-bottom: 1rem;
   }
   
   .btn-primary, .btn-success, .btn-danger {
    background-color: #007bff;
    border-color: #007bff;
   }
   
   .btn-primary:hover, .btn-success:hover, .btn-danger:hover {
    background-color: #0056b3;
    border-color: #004499;
   }
   

/* Style the label to look like a button */
label.btn {
    cursor: pointer;
   }
   
   /* Style the label to look like a button */
   label.btn {
    cursor: pointer;
   }
   
   /* Position the finalize button at the bottom */
   .upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
   }