/* ... other styles remain the same */

/* Add styles for the table */
.table-container {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  background: #ffffff;
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.15);
  border-radius: 8px;
  overflow: auto;
  max-height: 500px; /* Adjust as needed */
}

.table-container th, .table-container td {
  padding: 8px;
  width: 20%; /* Adjusted width to accommodate 5 columns */
}

.table-container th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #007BFF; /* Changed color to blue */
  color: white;
  border-radius: 0; /* Removed rounded corners */
}

.table-container tbody tr {
  height: 100px;
  border-bottom: 1px solid #ddd; /* Added border to separate rows */
}

.table-container tbody tr:last-child {
  border-bottom: none; /* Remove border from last row */
}

.table-container tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table-container tbody tr:hover {
  background-color: #ddd;
}

/* ... other styles remain the same */

.container {
    max-width: 960px;
   }
   
   .card-container {
    display: flex;
    flex-direction: column; /* Stack cards vertically */
    gap: 1rem; /* Space between cards */
   }
   
   .card {
    margin-bottom: 1rem;
    border-radius: 0.25rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
   }
   
   .card-body {
    padding: 1rem;
   }
   
   .card-title {
    margin-bottom: 0.5rem;
   }

   .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 90%;
  }
  
  .close {
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }