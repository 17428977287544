/* ticket-scanning.css */
.ticket-scanning-container {
    background-color: #ffffff; /* Light background */
    color: #000000; /* Dark text color */
    font-family: 'Roboto', sans-serif;
    padding: 20px;
  }
  
  .ticket-scanning-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .ticket-scanning-header h1 {
    margin: 0;
    font-size: 24px;
    color: #000000;
  }
  
  .ticket-scanning-header p {
    margin: 5px 0 0;
    font-size: 16px;
    color: #666666; /* Grey text */
  }
  
  .ticket-scanning-tabs {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .ticket-scanning-tabs button {
    background: none;
    border: none;
    color: #000000;
    font-size: 16px;
    padding: 10px;
    cursor: pointer;
  }
  
  .ticket-scanning-tabs button.active {
    border-bottom: 2px solid #053c8f; /* Primary blue for active tab */
  }
  
  .ticket-scanning-search-bar {
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  .ticket-scanning-search-bar input {
    background: none;
    border: none;
    color: #000000;
    flex-grow: 1;
    margin-right: 10px;
  }
  
  .ticket-scanning-search-bar button {
    background: none;
    border: none;
    color: #000000;
    cursor: pointer;
  }
  
  .ticket-scanning-card {
    background-color: #f5f5f5; /* Light grey for cards */
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  
  .ticket-scanning-card:hover {
    background-color: #e0e0e0; /* Slightly darker grey on hover */
  }
  
  .ticket-scanning-card .info {
    display: flex;
    align-items: center;
  }
  
  .ticket-scanning-card .info img {
    border-radius: 50%;
    margin-right: 10px;
    width: 50px;
    height: 50px;
  }
  
  .ticket-scanning-card .info div {
    display: flex;
    flex-direction: column;
  }
  
  .ticket-scanning-card .info div span {
    color: #000000;
  }
  
  .ticket-scanning-card .status {
    background-color: #4caf50; /* Green for confirmed status */
    color: #ffffff;
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  .divider {
    justify-content: center;
    display: flex;
    color: #808080;
  }
  .checkedInBody {
    background-color: #ffffff;
    color: #000000;
    padding: 20px;
  }
  
  .checkin-scanner-container {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .checkin-progress-info {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .checkin-progress-info div {
    text-align: center;
  }
  
  .checkin-progress-info span {
    display: block;
    font-size: 24px;
    font-weight: bold;
  }
  
  .checkin-progress-info p {
    margin: 5px 0 0;
    font-size: 14px;
    color: #666666;
  }
  
  .checkin-scan-button {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .checkin-search-bar {
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  .checkin-search-bar input {
    background: none;
    border: none;
    color: #000000;
    flex-grow: 1;
    margin-right: 10px;
  }
  
  .checkin-search-bar button {
    background: none;
    border: none;
    color: #000000;
    cursor: pointer;
  }
  
  .checkin-ticket {
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .checkin-ticket img {
    border-radius: 50%;
    margin-right: 10px;
    width: 50px;
    height: 50px;
  }
  
  .scrollable-list {
    max-height: 400px;
    overflow-y: auto;
    padding: 0;
  }
  
  .scrollable-list::-webkit-scrollbar {
    width: 5px;
  }
  
  .scrollable-list::-webkit-scrollbar-thumb {
    background-color: #cccccc;
  }
  
  .mobile-tabs .nav-link.active {
    background-color: #007bff;
    color: #ffffff !important;
  }

  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* This makes the container take up the full height of the viewport */
  }
  