.container {
    max-width: 960px;
   }
   
   .card {
    border-radius: 0.25rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
   }
   
   .card-img-top {
    width: 100%;
    height: auto;
   }
   
   .card-title {
    margin-bottom: 1rem;
   }
   
   .card-text {
    font-size: 1.1rem;
    line-height: 1.5;
   }
   
   .btn {
    font-size: 1rem;
    padding: 0.5rem 1rem;
   }
   
   .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
   }
   
   .btn-primary:hover {
    background-color: #0056b3;
    border-color: #004499;
   }
   
   .btn-secondary {
    background-color: #6c757d;
    border-color: #6c757d;
   }
   
   .btn-secondary:hover {
    background-color: #5a6268;
    border-color: #545b62;
   }
   
   .btn-success {
    background-color: #28a745;
    border-color: #28a745;
   }
   
   .btn-success:hover {
    background-color: #218838;
    border-color: #1e7e34;
   }