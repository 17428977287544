.link-button-container {
    display: flex;
    justify-content: flex-end;
   }
.frost-grey-button {
    background-color: #f0f0f0; /* Frosted grey background */
    border: none; /* Remove default button border */
    color: #333; /* Text color */
    padding: 10px 20px; /* Padding */
    text-align: center; /* Centered text */
    text-decoration: none; /* Remove underline */
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px; /* Rounded corners */
   }
   
   .frost-grey-button svg {
    margin-right: 5px; /* Space between the icon and the text */
   }

   .frost-glass {
 background: rgba(255, 255, 255, 0.1); /* Semi-transparent background */
 backdrop-filter: blur(10px); /* Apply blur effect */
 border-radius: 10px; /* Optional: rounded corners */
 padding: 20px; /* Space inside the frosted glass container */
}